<template>
  <v-container>
    <div v-if="$apollo.loading" />
    <v-row v-else justify="center">
      <v-col cols="12" md="5">
        <j-alert v-if="successMessage && !isRecurringPurchase" type="success">
          {{ successMessage }}
          <br /><br />
          <j-btn to="/today" wide max-width="310" color="green"
            >Continue <v-icon size="19">mdi-arrow-right</v-icon></j-btn
          >
        </j-alert>

        <j-card v-else class="text-center pa-6">
          <img :src="require('@/assets/images/completed.png')" width="100" />

          <h2 class="green--text mt-2">Success</h2>

          <div class="green--text std-text mt-4">Your payment has been successful 🎉</div>

          <div class="std-text mt-6" style="max-width: 350px; margin-left: auto; margin-right: auto">
            Check your email inbox for more information.
            <br /><br />
            <span v-if="isNewUser">
              Download the <b>JEFF Fitness App</b> on your <b>mobile device</b> and login with your details to get
              started.</span
            >
            <span v-else>
              If you haven't done so yet, download the <b>JEFF Fitness app</b> on your <b>mobile device</b> and login
              with your details to get started. <br /><br />If you already have the app installed, you will be able to
              login or pull down to refresh to unlock all features.
            </span>

            <br /><br />
          </div>

          <native-app-icons class="mt-4" />

          <div class="pt-3 std-text">
            or continue using the <router-link to="/" class="text-decoration-underline">web portal</router-link>.
          </div>
        </j-card></v-col
      ></v-row
    >
  </v-container>
</template>

<script>
import { PAYMENT_QUERY } from "@/graphql/queries/store";
import NativeAppIcons from "@/components/shared/NativeAppIcons";

export default {
  name: "CheckoutSuccess",
  components: {
    NativeAppIcons,
  },
  props: {
    paymentId: {
      type: [Number, String],
      required: true,
    },
  },
  computed: {
    successMessage() {
      if (this.payment?.order?.productVariants?.edges?.length > 0) {
        return this.payment.order.productVariants.edges[0].node.product.successMessage;
      }
      return null;
    },
    isRecurringPurchase() {
      if (this.payment?.order?.productVariants?.edges?.length > 0) {
        return this.payment.order.productVariants.edges[0].node.product.isRecurring;
      }
      return false;
    },
    isNewUser() {
      return this.payment?.order?.user?.userProfile?.activeSubscription?.acquisitionType == "NEW";
    },
  },
  apollo: {
    payment: {
      query: PAYMENT_QUERY,
      variables() {
        return {
          paymentId: this.paymentId,
        };
      },
      fetchPolicy: "network-only",
    },
  },
};
</script>
