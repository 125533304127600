import { render, staticRenderFns } from "./TheBottomNav.vue?vue&type=template&id=c6cd8618&scoped=true"
import script from "./TheBottomNav.vue?vue&type=script&lang=js"
export * from "./TheBottomNav.vue?vue&type=script&lang=js"
import style0 from "./TheBottomNav.vue?vue&type=style&index=0&id=c6cd8618&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6cd8618",
  null
  
)

export default component.exports